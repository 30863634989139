import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard'
import { AutoLoginGuard } from './guards/auto-login.guard';
import { DataResolverService } from './resolver/data-resolver.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'doctor-profile',
    loadChildren: () => import('./doctor-profile/doctor-profile.module').then( m => m.DoctorProfilePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'patient-list',
    loadChildren: () => import('./patient-list/patient-list.module').then( m => m.PatientListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },
  {
    path: 'doctor-appointment-list',
    loadChildren: () => import('./doctor-appointment-list/doctor-appointment-list.module').then( m => m.DoctorAppointmentListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'doctor-eprescription/:id',
    resolve: {
      videoPrescription: DataResolverService
    },
    loadChildren: () => import('./doctor-eprescription/doctor-eprescription.module').then( m => m.DoctorEPrescriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'doctor-billing',
    loadChildren: () => import('./doctor-billing/doctor-billing.module').then( m => m.DoctorBillingPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'doctor-upload-medical-record',
    loadChildren: () => import('./doctor-upload-medical-record/doctor-upload-medical-record.module').then( m => m.DoctorUploadMedicalRecordPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'doctor-hospital-list',
    loadChildren: () => import('./doctor-hospital-list/doctor-hospital-list.module').then( m => m.DoctorHospitalListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pt-video-appt-detail',
    loadChildren: () => import('./pt-video-appt-detail/pt-video-appt-detail.module').then( m => m.PtVideoApptDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'followup-circle-list',
    loadChildren: () => import('./followup-circle-list/followup-circle-list.module').then( m => m.FollowupCircleListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'folllowup-circle-create-join',
    loadChildren: () => import('./folllowup-circle-create-join/folllowup-circle-create-join.module').then( m => m.FolllowupCircleCreateJoinPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-circle/:id',
    resolve: {
      ccode: DataResolverService
    },
    loadChildren: () => import('./followup-circle/followup-circle.module').then( m => m.FollowupCirclePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-tagging',
    loadChildren: () => import('./followup-tagging/followup-tagging.module').then( m => m.FollowupTaggingPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-circle-setting/:id',
    resolve: {
      circleSettingObj: DataResolverService
    },
    loadChildren: () => import('./followup-circle-setting/followup-circle-setting.module').then( m => m.FollowupCircleSettingPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-patient-list/:id',
    resolve: {
      ptListData: DataResolverService
    },
    loadChildren: () => import('./followup-patient-list/followup-patient-list.module').then( m => m.FollowupPatientListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-qrcode-share',
    loadChildren: () => import('./followup-qrcode-share/followup-qrcode-share.module').then( m => m.FollowupQrcodeSharePageModule),
    //canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-share-circle',
    loadChildren: () => import('./followup-share-circle/followup-share-circle.module').then( m => m.FollowupShareCirclePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-chat-room',
    loadChildren: () => import('./followup-chat-room/followup-chat-room.module').then( m => m.FollowupChatRoomPageModule), 
    // canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }   
  },
  {
    path: 'followup-doctors-detail',
    loadChildren: () => import('./followup-doctors-detail/followup-doctors-detail.module').then( m => m.FollowupDoctorsDetailPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-alldoctors-list',
    loadChildren: () => import('./followup-alldoctors-list/followup-alldoctors-list.module').then( m => m.FollowupAlldoctorsListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-create-join',
    loadChildren: () => import('./followup-create-join/followup-create-join.module').then( m => m.FollowupCreateJoinPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-plan-create',
    loadChildren: () => import('./followup-plan-create/followup-plan-create.module').then( m => m.FollowupPlanCreatePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-plan-edit',
    loadChildren: () => import('./followup-plan-edit/followup-plan-edit.module').then( m => m.FollowupPlanEditPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-picture-edit',
    loadChildren: () => import('./followup-picture-edit/followup-picture-edit.module').then( m => m.FollowupPictureEditPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'clinic-followup-manage-consultation',
    loadChildren: () => import('./clinic-followup-manage-consultation/clinic-followup-manage-consultation.module').then( m => m.ClinicFollowupManageConsultationPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'video-manage-consultation',
    loadChildren: () => import('./video-manage-consultation/video-manage-consultation.module').then( m => m.VideoManageConsultationPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then( m => m.VideoPageModule),
     canActivate: [AuthGuard]
  },
  {
    path: 'chatroom/:id',
    resolve: {
      chatData: DataResolverService
    },
    loadChildren: () => import('./chatroom/chatroom.module').then( m => m.ChatroomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'preview-gallery-image',
    loadChildren: () => import('./preview-gallery-image/preview-gallery-image.module').then( m => m.PreviewGalleryImagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat-room-file-upload-page',
    loadChildren: () => import('./chat-room-file-upload-page/chat-room-file-upload-page.module').then( m => m.ChatRoomFileUploadPagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'popover-content-page',
    loadChildren: () => import('./popover-content-page/popover-content-page.module').then( m => m.PopoverContentPagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'followup-chat-popover',
    loadChildren: () => import('./followup-chat-popover/followup-chat-popover.module').then( m => m.FollowupChatPopoverPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-complete-chatpop',
    loadChildren: () => import('./followup-complete-chatpop/followup-complete-chatpop.module').then( m => m.FollowupCompleteChatpopPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'presc-notes-tab/:id',
    resolve: {
      prescViewData: DataResolverService
    },
    loadChildren: () => import('./presc-notes-tab/presc-notes-tab.module').then( m => m.PrescNotesTabPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'doc-notes',
    loadChildren: () => import('./doc-notes/doc-notes.module').then( m => m.DocNotesPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'presc-view-edit/:id',
    resolve: {
      prescViewData: DataResolverService
    },
    loadChildren: () => import('./presc-view-edit/presc-view-edit.module').then( m => m.PrescViewEditPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-eprescription/:id',
    resolve: {
      newPrescData: DataResolverService
    },
    loadChildren: () => import('./followup-eprescription/followup-eprescription.module').then( m => m.FollowupEprescriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'medicine-edit',
    loadChildren: () => import('./medicine-edit/medicine-edit.module').then( m => m.MedicineEditPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'bank-detail-form',
    loadChildren: () => import('./bank-detail-form/bank-detail-form.module').then( m => m.BankDetailFormPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'bank-detail-view',
    loadChildren: () => import('./bank-detail-view/bank-detail-view.module').then( m => m.BankDetailViewPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'followup-patient-subscription',
    loadChildren: () => import('./followup-patient-subscription/followup-patient-subscription.module').then( m => m.FollowupPatientSubscriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'recep-appointment-list',
    loadChildren: () => import('./recep-appointment-list/recep-appointment-list.module').then( m => m.RecepAppointmentListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Receptionist'
    }
  },
  {
    path: 'nurse-appointment-list',
    loadChildren: () => import('./nurse-appointment-list/nurse-appointment-list.module').then( m => m.NurseAppointmentListPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Nurse'
    }
  },
  {
    path: 'nurse-profile',
    loadChildren: () => import('./nurse-profile/nurse-profile.module').then( m => m.NurseProfilePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Nurse'
    }
  },
  {
    path: 'recep-profile',
    loadChildren: () => import('./recep-profile/recep-profile.module').then( m => m.RecepProfilePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Receptionist'
    }
  },
  {
    path: 'nurse-vital/:id',
    resolve: {
      apptDetail: DataResolverService
    },
    loadChildren: () => import('./nurse-vital/nurse-vital.module').then( m => m.NurseVitalPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Nurse'
    }
  },
  {
    path: 'swasth-create-bill/:id',
    resolve: {
      billData: DataResolverService
    },
    loadChildren: () => import('./swasth-create-bill/swasth-create-bill.module').then( m => m.SwasthCreateBillPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },
  {
    path: 'bill-statistics/:id',
    resolve: {
      billStats: DataResolverService
    },
    loadChildren: () => import('./bill-statistics/bill-statistics.module').then( m => m.BillStatisticsPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'single-doc-appt-manage',
    loadChildren: () => import('./single-doc-appt-manage/single-doc-appt-manage.module').then( m => m.SingleDocApptManagePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'video-appt-waiting-room',
    loadChildren: () => import('./video-appt-waiting-room/video-appt-waiting-room.module').then( m => m.VideoApptWaitingRoomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-video-appt-waiting-room-pt',
    loadChildren: () => import('./view-video-appt-waiting-room-pt/view-video-appt-waiting-room-pt.module').then( m => m.ViewVideoApptWaitingRoomPtPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recep-billing',
    loadChildren: () => import('./recep-billing/recep-billing.module').then( m => m.RecepBillingPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Receptionist'
    }
  },
  {
    path: 'nurse-billing',
    loadChildren: () => import('./nurse-billing/nurse-billing.module').then( m => m.NurseBillingPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Nurse'
    }
  },
  {
    path: 'edit-pt-profile',
    loadChildren: () => import('./edit-pt-profile/edit-pt-profile.module').then( m => m.EditPtProfilePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },
  {
    path: 'doctor-plus-eprescription/:id',
    resolve: {
      prescriptionObj: DataResolverService
    },
    loadChildren: () => import('./doctor-plus-eprescription/doctor-plus-eprescription.module').then( m => m.DoctorPlusEprescriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse'
    }
  },
  {
    path: 'doctor-pt-list-with-presc',
    loadChildren: () => import('./doctor-pt-list-with-presc/doctor-pt-list-with-presc.module').then( m => m.DoctorPtListWithPrescPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'view-prescription/:id',
    resolve: {
      viewPrescObj: DataResolverService
    },
    loadChildren: () => import('./view-prescription/view-prescription.module').then( m => m.ViewPrescriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },
  {
    path: 'update-ccsl',
    loadChildren: () => import('./update-ccsl/update-ccsl.module').then( m => m.UpdateCcslPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'upload-file-options',
    loadChildren: () => import('./upload-file-options/upload-file-options.module').then( m => m.UploadFileOptionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-template',
    loadChildren: () => import('./create-template/create-template.module').then( m => m.CreateTemplatePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'edit-presc-medicine',
    loadChildren: () => import('./edit-presc-medicine/edit-presc-medicine.module').then( m => m.EditPrescMedicinePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'edit-presc-lab',
    loadChildren: () => import('./edit-presc-lab/edit-presc-lab.module').then( m => m.EditPrescLabPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor'
    }
  },
  {
    path: 'prescription-view-images',
    loadChildren: () => import('./prescription-view-images/prescription-view-images.module').then( m => m.PrescriptionViewImagesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-bill',
    loadChildren: () => import('./edit-bill/edit-bill.module').then( m => m.EditBillPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-bill-edit-history',
    loadChildren: () => import('./view-bill-edit-history/view-bill-edit-history.module').then( m => m.ViewBillEditHistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appt-created-info',    
    loadChildren: () => import('./appt-created-info/appt-created-info.module').then( m => m.ApptCreatedInfoPageModule)
  },
  {
    path: 'voice-eprescription/:id',
    resolve: {
      prescriptionObj: DataResolverService
    },
    loadChildren: () => import('./voice-eprescription/voice-eprescription.module').then( m => m.VoiceEprescriptionPageModule)
  },
  {
    path: 'chatgpt',
    loadChildren: () => import('./chatgpt/chatgpt.module').then( m => m.ChatgptPageModule)
  },
  {
    path: 'access-setting',
    loadChildren: () => import('./access-setting/access-setting.module').then( m => m.AccessSettingPageModule)
  },
  {
    path: 'weekly-report',
    loadChildren: () => import('./weekly-report/weekly-report.module').then( m => m.WeeklyReportPageModule)
  },
  {
    path: 'segment',
    loadChildren: () => import('./segment/segment.module').then( m => m.SegmentPageModule)
  },
  {
    path: 'showeditbillcomment',
    loadChildren: () => import('./showeditbillcomment/showeditbillcomment.module').then( m => m.ShoweditbillcommentPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },
  {
    path: 'calendarbook-appointment',
    loadChildren: () => import('./calendarbook-appointment/calendarbook-appointment.module').then( m => m.CalendarbookAppointmentPageModule)
  },
  {
    path: 'doctorapptslots',
    loadChildren: () => import('./doctorapptslots/doctorapptslots.module').then( m => m.DoctorapptslotsPageModule)
  },
  {
    path: 'calendar-nurse-appointment',
    loadChildren: () => import('./calendar-nurse-appointment/calendar-nurse-appointment.module').then( m => m.CalendarNurseAppointmentPageModule)
  },
  {
    path: 'sendnotificationpopup',
    loadChildren: () => import('./sendnotificationpopup/sendnotificationpopup.module').then( m => m.SendnotificationpopupPageModule)
  },
  {
    path: 'calendar-receptionist-appointment',
    loadChildren: () => import('./calendar-receptionist-appointment/calendar-receptionist-appointment.module').then( m => m.CalendarReceptionistAppointmentPageModule)
  },
  {
    path: 'eventscolor',
    loadChildren: () => import('./eventscolor/eventscolor.module').then( m => m.EventscolorPageModule)
  },
  {
    path: 'processloader',
    loadChildren: () => import('./processloader/processloader.module').then( m => m.ProcessloaderPageModule)
  },
  {
    path: 'view-patient-prescription/:id',
    resolve: {
      viewPatientPrescObj: DataResolverService
    },
    loadChildren: () => import('./view-patient-prescription/view-patient-prescription.module').then( m => m.ViewPatientPrescriptionPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Doctor Nurse Receptionist'
    }
  },  {
    path: 'viewpatientaddress',
    loadChildren: () => import('./viewpatientaddress/viewpatientaddress.module').then( m => m.ViewpatientaddressPageModule)
  },
  {
    path: 'showtreatmentplan',
    loadChildren: () => import('./showtreatmentplan/showtreatmentplan.module').then( m => m.ShowtreatmentplanPageModule)
  },
  {
    path: 'zoomimage',
    loadChildren: () => import('./zoomimage/zoomimage.module').then( m => m.ZoomimagePageModule)
  },
  {
    path: 'pharmacy-profile',
    loadChildren: () => import('./pharmacy-profile/pharmacy-profile.module').then( m => m.PharmacyProfilePageModule)
  },
  {
    path: 'pharmacy-products',
    loadChildren: () => import('./pharmacy-products/pharmacy-products.module').then( m => m.PharmacyProductsPageModule)
  },
  {
    path: 'add-pharmacy-product',
    loadChildren: () => import('./add-pharmacy-product/add-pharmacy-product.module').then( m => m.AddPharmacyProductPageModule)
  },
  {
    path: 'pharmacy-purchase',
    loadChildren: () => import('./pharmacy-purchase/pharmacy-purchase.module').then( m => m.PharmacyPurchasePageModule)
  },
  {
    path: 'pharmacy-supplier',
    loadChildren: () => import('./pharmacy-supplier/pharmacy-supplier.module').then( m => m.PharmacySupplierPageModule)
  },
  {
    path: 'pharmacy-manufacturers',
    loadChildren: () => import('./pharmacy-manufacturers/pharmacy-manufacturers.module').then( m => m.PharmacyManufacturersPageModule)
  },
  {
    path: 'add-pharmacy-purchase-order',
    loadChildren: () => import('./add-pharmacy-purchase-order/add-pharmacy-purchase-order.module').then( m => m.AddPharmacyPurchaseOrderPageModule)
  },
  {
    path: 'pharmacy-batch-master',
    loadChildren: () => import('./pharmacy-batch-master/pharmacy-batch-master.module').then( m => m.PharmacyBatchMasterPageModule)
  },
  {
    path: 'addpharmacy-batch-master',
    loadChildren: () => import('./addpharmacy-batch-master/addpharmacy-batch-master.module').then( m => m.AddpharmacyBatchMasterPageModule)
  },
  {
    path: 'add-pharmacy-supplier',
    loadChildren: () => import('./add-pharmacy-supplier/add-pharmacy-supplier.module').then( m => m.AddPharmacySupplierPageModule)
  },
  {
    path: 'add-pharmacy-batch-master-po',
    loadChildren: () => import('./add-pharmacy-batch-master-po/add-pharmacy-batch-master-po.module').then( m => m.AddPharmacyBatchMasterPoPageModule)
  },
  {
    path: 'edit-pharmacy-product',
    loadChildren: () => import('./edit-pharmacy-product/edit-pharmacy-product.module').then( m => m.EditPharmacyProductPageModule)
  },
  {
    path: 'billreport',
    loadChildren: () => import('./billreport/billreport.module').then( m => m.BillreportPageModule)
  },
  {
    path: 'purchase-order-history',
    loadChildren: () => import('./purchase-order-history/purchase-order-history.module').then( m => m.PurchaseOrderHistoryPageModule)
  },
  {
    path: 'medicine-history',
    loadChildren: () => import('./medicine-history/medicine-history.module').then( m => m.MedicineHistoryPageModule)
  },
  {
    path: 'pharmacy-master',
    loadChildren: () => import('./pharmacy-master/pharmacy-master.module').then( m => m.PharmacyMasterPageModule)
  },
  {
    path: 'add-pharmacy-product-type',
    loadChildren: () => import('./add-pharmacy-product-type/add-pharmacy-product-type.module').then( m => m.AddPharmacyProductTypePageModule)
  },
  {
    path: 'add-pharmacy-medicine-type',
    loadChildren: () => import('./add-pharmacy-medicine-type/add-pharmacy-medicine-type.module').then( m => m.AddPharmacyMedicineTypePageModule)
  },
  {
    path: 'add-pharmacy-pack-type',
    loadChildren: () => import('./add-pharmacy-pack-type/add-pharmacy-pack-type.module').then( m => m.AddPharmacyPackTypePageModule)
  },
  {
    path: 'medicine-report',
    loadChildren: () => import('./medicine-report/medicine-report.module').then( m => m.MedicineReportPageModule)
  },
  {
    path: 'addpharmacyhsn',
    loadChildren: () => import('./addpharmacyhsn/addpharmacyhsn.module').then( m => m.AddpharmacyhsnPageModule)
  },
  {
    path: 'pharmacy-product-report',
    loadChildren: () => import('./pharmacy-product-report/pharmacy-product-report.module').then( m => m.PharmacyProductReportPageModule)
  },
  {
    path: 'doctor-pharmacy-product-report',
    loadChildren: () => import('./doctor-pharmacy-product-report/doctor-pharmacy-product-report.module').then( m => m.DoctorPharmacyProductReportPageModule)
  },
  {
    path: 'add-otherproduct-batch-master-po',
    loadChildren: () => import('./add-otherproduct-batch-master-po/add-otherproduct-batch-master-po.module').then( m => m.AddOtherproductBatchMasterPoPageModule)
  }






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: true })
    // RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
