import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { SwstoragService } from '../storage/swstorag.service';
import { ModulesApisService } from '../services/modules-apis.service';
import { SWASTH_CONST } from '../constant';

const TOKEN_KEY = 'swplus-token';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {

  constructor(private router: Router, 
    private authService: AuthenticationService, private swstorage: SwstoragService,
    private modulesApis: ModulesApisService) {}

  canLoad(): Observable<boolean> {
    return this.authService.isRemember.pipe(
      filter(val => val !== null),
      take(1),
      map(isRemember => {
        console.log("Guard", isRemember);
        if (isRemember) {
          // alert(SWASTH_CONST.APPOINTMENTMODE);
          if(this.modulesApis.decodedToken.type=="Doctor")
          {
            if(SWASTH_CONST.APPOINTMENTMODE=='Normal')
            {
              this.router.navigateByUrl('/doctor-appointment-list');
            }
            else
            {
              this.router.navigateByUrl('/calendar');
            }
            
          }
          if(this.modulesApis.decodedToken.type=="Nurse")
          {
            if(SWASTH_CONST.APPOINTMENTMODE=='Normal')
            {
            this.router.navigateByUrl('/nurse-appointment-list');
            }
            else
            {
              this.router.navigateByUrl('/calendar');
            }
          }
          if(this.modulesApis.decodedToken.type=="Receptionist")
          {
            if(SWASTH_CONST.APPOINTMENTMODE=='Normal')
            {
            this.router.navigateByUrl('/recep-appointment-list');
            }
            else
            {
              this.router.navigateByUrl('/calendar');
            }
          }
          if(this.modulesApis.decodedToken.type=="PharmacyUser")
          {
            this.router.navigateByUrl('/pharmacy-profile');
          }
        } else {        
          this.swstorage.removeStorage(TOKEN_KEY);
          return true;
        }
      }

      )
    );
  }
  
}
