import { Component } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { DoctorHospitalListPage } from './doctor-hospital-list/doctor-hospital-list.page';
import * as firebase from 'firebase/app';
import { ModulesApisService } from './services/modules-apis.service';
import { Router } from '@angular/router';
import { SWASTH_CONST } from './constant';
import { SwstoragService } from './storage/swstorag.service';
import { WebSocketService } from "./services/web-socket.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
//import { SplashScreen } from '@ionic-native/splash-screen';

const helper = new JwtHelperService();
const TOKEN_KEY = 'swplus-token';


const config = {
  apiKey: "AIzaSyAjNZDNJiDWa7E3ADeMZewHksNLA4qz4-c",
  authDomain: "swasth-plus.firebaseapp.com",
  databaseURL: "https://swasth-plus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "swasth-plus",
  storageBucket: "swasth-plus.appspot.com",
  messagingSenderId: "75257375636",
  appId: "1:75257375636:web:6b7fc8d17d8775111c3d81",
  measurementId: "G-7FZDSD55K6"
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userType: any;
  public appPages: any = [];
  private resumesub1: any;
  token = '';
  tenantid: any;
  accessSetting: any = [];
  hospitalDetails:any;

  constructor(private popoverCtrl: PopoverController,
    private router: Router,
    private platform: Platform,
    private modulesApis: ModulesApisService,
    private swstorage: SwstoragService,
    private webSocket: WebSocketService,
    private storage: Storage) {

    let urlStr=window.location.href;
    let Uri = urlStr.split("/");
    if(Uri[2].includes("localhost") || Uri[2].includes("172.16.100.148")) {
      Uri[2]="SWASTH";
      SWASTH_CONST.HOSPITALTENANTURL=Uri[2];
    } else {
      SWASTH_CONST.HOSPITALTENANTURL=Uri[2].slice(0,-14);
    }

    // SWASTH_CONST.HOSPITALTENANTURL = "NUO";

    this.initializeApp();
  }

  ngOnInit() {
    let userToken: any;
    this.platform.ready().then(() => {
      // alert("plt ready");
      this.resumesub1 = this.platform.resume.subscribe(() => {
        //alert("resume wake up");
        this.swstorage.getStorage("swplus-token").then(token => {
          userToken = token;
          //alert("user token ck");
          //this.webSocket.subcribe(userToken.UID, true, userToken.type, userToken.TenantID);
        });
      });
    });
  //   if (this.modulesApis.decodedToken != undefined) {
  //     console.log(this.userType);
  //     this.userType = this.modulesApis.decodedToken.type;
  //     this.tenantid = this.modulesApis.decodedToken.TenantID;
  //     console.log(this.userType, this.tenantid);
  //   this.modulesApis.getHospitalDetailsByTenantid(this.tenantid).subscribe(hospData=>{
  //     this.hospitalDetails=hospData;
  //     console.log(this.hospitalDetails);
  //     alert(this.hospitalDetails.pharmacyOption);
  //   });
  // }
  }

  ionViewDidLeave() {
    this.resumesub1.unsubscribe();
  }

  initializeApp() {
    //this.splashScreen.show();
    // let loginUserDetail:any;
    // this.swstorage.getStorage("DOCTORPROFILE").then(logindata => {
    //   loginUserDetail = logindata; 
    // this.webSocket.subcribe(loginUserDetail.docUserID,true);
    // });
    this.platform.ready().then(() => {
      //this.splashScreen.hide();
      this.openMenu();
      firebase.initializeApp(config);
      
    });
  }



  async openMenu() {
    //console.log(this.userType);
    if (this.modulesApis.decodedToken != undefined) {
      console.log(this.userType);
      this.userType = this.modulesApis.decodedToken.type;
      this.tenantid = this.modulesApis.decodedToken.TenantID;
      console.log(this.userType, this.tenantid);
      this.modulesApis.getHospitalDetailsByTenantid(this.tenantid).subscribe(hospdata=>{
        this.hospitalDetails=hospdata;
      
      
      if (this.userType == "Doctor") {
        if (this.tenantid == "ACLC") {
          this.appPages = [
            { title: 'Profile', url: '/doctor-profile', icon: "person-outline" },
            { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
            { title: 'Patients Prescription', url: '/doctor-pt-list-with-presc', icon: "people-outline" },
            { title: 'Appointments', url: '/doctor-appointment-list', icon: "calendar-outline" },
            { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
            //{ title: 'E-Prescription', url: (SWASTH_CONST.HOSPITALTENANTURL = 'DESK') ? '/voice-eprescription/312':'doctor-plus-eprescription/312', icon: "create-outline" },
            // { title: 'Follow Up', url: '/followup-circle-list', icon: "create-outline" },
            { title: 'Bills', url: '/doctor-billing', icon: "card-outline" },
            //{ title: 'Add a Medical Record', url: '/doctor-upload-medical-record', icon: "cloud-upload-outline"},
            // { title: 'doc appt manage', url: '/single-doc-appt-manage', icon: "settings" },
            // { title: 'Followup Subscription', url: '/followup-patient-subscription', icon: "cloud-upload-outline" },
            { title: 'Template Setting', url: '/create-template', icon: "clipboard-outline" },
            // { title: 'Prescription Assistant', url: '/chatgpt/jaccard', icon: "logo-wechat" },
            // { title: 'Prescription Assistant new', url: '/chatgpt/cosine', icon: "logo-wechat" },
            { title: 'Prescription Assistant', url: '/chatgpt', icon: "logo-wechat" },
            { title: 'Weekly Insights', url: '/weekly-report', icon: "newspaper-outline" },
          ];
        }
        else {
          if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
            this.appPages = [
              { title: 'Profile', url: '/doctor-profile', icon: "person-outline" },
              { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
              { title: 'Patients Prescription', url: '/doctor-pt-list-with-presc', icon: "people-outline" },
              { title: 'Appointments', url: '/doctor-appointment-list', icon: "calendar-outline" },
              { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
              //{ title: 'E-Prescription', url: (SWASTH_CONST.HOSPITALTENANTURL = 'DESK') ? '/voice-eprescription/312':'doctor-plus-eprescription/312', icon: "create-outline" },
              // { title: 'Follow Up', url: '/followup-circle-list', icon: "create-outline" },
              { title: 'Bills', url: '/doctor-billing', icon: "card-outline" },
              //{ title: 'Add a Medical Record', url: '/doctor-upload-medical-record', icon: "cloud-upload-outline"},
              // { title: 'doc appt manage', url: '/single-doc-appt-manage', icon: "settings" },
              // { title: 'Followup Subscription', url: '/followup-patient-subscription', icon: "cloud-upload-outline" },
              { title: 'Template Setting', url: '/create-template', icon: "clipboard-outline" },
              // { title: 'Camera', url: '/cameraupload', icon: "clipboard-outline" }
              // { title: 'Prescription Assistant', url: '/chatgpt/jaccard', icon: "logo-wechat" },
              // { title: 'Prescription Assistant new', url: '/chatgpt/cosine', icon: "logo-wechat" },
              // { title: 'Prescription Assistant', url: '/chatgpt', icon: "logo-wechat" },
              { title: 'Weekly Insights', url: '/weekly-report', icon: "newspaper-outline" },
              // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
            ];
          }
          else {
            this.appPages = [
              { title: 'Profile', url: '/doctor-profile', icon: "person-outline" },
              { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
              { title: 'Patients Prescription', url: '/doctor-pt-list-with-presc', icon: "people-outline" },
              { title: 'Appointments', url: '//calendar', icon: "calendar-outline" },
              { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
              //{ title: 'E-Prescription', url: (SWASTH_CONST.HOSPITALTENANTURL = 'DESK') ? '/voice-eprescription/312':'doctor-plus-eprescription/312', icon: "create-outline" },
              // { title: 'Follow Up', url: '/followup-circle-list', icon: "create-outline" },
              { title: 'Bills', url: '/doctor-billing', icon: "card-outline" },
              //{ title: 'Add a Medical Record', url: '/doctor-upload-medical-record', icon: "cloud-upload-outline"},
              // { title: 'doc appt manage', url: '/single-doc-appt-manage', icon: "settings" },
              // { title: 'Followup Subscription', url: '/followup-patient-subscription', icon: "cloud-upload-outline" },
              { title: 'Template Setting', url: '/create-template', icon: "clipboard-outline" },
              // { title: 'Camera', url: '/cameraupload', icon: "clipboard-outline" }
              // { title: 'Prescription Assistant', url: '/chatgpt/jaccard', icon: "logo-wechat" },
              // { title: 'Prescription Assistant new', url: '/chatgpt/cosine', icon: "logo-wechat" },
              // { title: 'Prescription Assistant', url: '/chatgpt', icon: "logo-wechat" },
              { title: 'Weekly Insights', url: '/weekly-report', icon: "newspaper-outline" },
              // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
            ];
          }

        }

        // this.hospListPopover();
      } 
      else if (this.userType == "Nurse") 
      {
        if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
          if (this.tenantid == "PEAK" || this.tenantid == "orthoveda") {
            if(this.hospitalDetails.pharmacyOption==null || this.hospitalDetails.pharmacyOption=='no')
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" }                
              ];
            }
            else
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
                // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
                { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },          
                // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
                // { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
              ];
            }
            
          } else {
            if(this.hospitalDetails.pharmacyOption==null || this.hospitalDetails.pharmacyOption=='no')
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" }                
              ];
            }
            else
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                // { title: 'Product Report', url: '/pharmacy-product-report', icon: "people-outline" },
                // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
                // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
                { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },          
                // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
                // { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
              ];
            }
            
          }
        }
        else {
          if (this.tenantid == "PEAK" || this.tenantid == "orthoveda") {
            if(this.hospitalDetails.pharmacyOption==null || this.hospitalDetails.pharmacyOption=='no')
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" }                
              ];
            }
            else
            {
              this.appPages = [
                { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                // { title: 'Product Report', url: '/pharmacy-product-report', icon: "people-outline" },
                // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
                // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
                { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },          
                // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
                { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
              ];
            }
            
          } else {
            this.appPages = [
              { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
              { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
              { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
              { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
              { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
              { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
              // { title: 'Product Report', url: '/pharmacy-product-report', icon: "people-outline" },
              // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
              // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
              { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },          
              // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
              { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
              { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
              { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
              // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
            ];
          }
        }
      } 
      else if (this.userType == "Receptionist") 
      {
        if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
          this.appPages = [
            { title: 'Profile', url: '/recep-profile', icon: "person-outline" },
            { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
            { title: 'Appointments', url: '/recep-appointment-list', icon: "calendar-outline" },
            { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
            // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
          ];
        }
        else {
          this.appPages = [
            { title: 'Profile', url: '/recep-profile', icon: "person-outline" },
            { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
            { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
            { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
            // { title: 'Calendar', url: '/calendar', icon: "newspaper-outline" }
          ];
        }
      }
      else if (this.userType == "Pharmacy") {
        this.appPages = [
          { title: 'Profile', url: '/pharmacy-profile', icon: "person-outline" },
          { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
          // { title: 'Product Report', url: '/pharmacy-product-report', icon: "people-outline" },
          // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
          // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
          { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },                    
          // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
          { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
          { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
          { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
          
          // { title: 'Medicine History', url: '/medicine-history', icon: "newspaper-outline" },
          // { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
          // { title: 'Inventory', url: '/calendar', icon: "newspaper-outline" },
          // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
          // { title: 'Tax', url: '/calendar', icon: "newspaper-outline" }

        ];
      }
    });
    }
    else {
      if (this.storage.get(TOKEN_KEY)) {
        console.log(': ', this.storage.get(TOKEN_KEY));
        const token = await this.storage.get(TOKEN_KEY);
        if (token) {
          console.log('set token: ', token);
          this.token = token;
          let decodedToken = helper.decodeToken(this.token);
          this.modulesApis.decodedToken = helper.decodeToken(this.token);
          console.log(decodedToken);
          this.userType = decodedToken.type;
          this.tenantid = this.modulesApis.decodedToken.TenantID;
          this.modulesApis.getHospitalDetailsByTenantid(this.tenantid).subscribe(hospdata=>{
          this.hospitalDetails=hospdata;
          console.log(this.userType);
          // alert(this.userType);
          if (this.userType == "Doctor") {
            if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
              this.appPages = [
                { title: 'Profile', url: '/doctor-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Patients Prescription', url: '/doctor-pt-list-with-presc', icon: "people-outline" },
                { title: 'Appointments', url: '/doctor-appointment-list', icon: "calendar-outline" },
                { title: 'E-Prescription', url: '/doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Follow Up', url: '/followup-circle-list', icon: "create-outline" },
                { title: 'Bills', url: '/doctor-billing', icon: "card-outline" },
                //{ title: 'Add a Medical Record', url: '/doctor-upload-medical-record', icon: "cloud-upload-outline"},
                { title: 'doc appt manage', url: '/single-doc-appt-manage', icon: "settings" },
                { title: 'Followup Subscription', url: '/followup-patient-subscription', icon: "cloud-upload-outline" },
                { title: 'Template Setting', url: '/create-template', icon: "clipboard-outline" },
              ];
            }
            else {
              this.appPages = [
                { title: 'Profile', url: '/doctor-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Patients Prescription', url: '/doctor-pt-list-with-presc', icon: "people-outline" },
                { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                { title: 'E-Prescription', url: '/doctor-plus-eprescription/312', icon: "create-outline" },
                { title: 'Follow Up', url: '/followup-circle-list', icon: "create-outline" },
                { title: 'Bills', url: '/doctor-billing', icon: "card-outline" },
                //{ title: 'Add a Medical Record', url: '/doctor-upload-medical-record', icon: "cloud-upload-outline"},
                { title: 'doc appt manage', url: '/single-doc-appt-manage', icon: "settings" },
                { title: 'Followup Subscription', url: '/followup-patient-subscription', icon: "cloud-upload-outline" },
                { title: 'Template Setting', url: '/create-template', icon: "clipboard-outline" },
              ];
            }
            // this.hospListPopover();
          } else if (this.userType == "Nurse") {
            if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
              if (this.tenantid == "PEAK" || this.tenantid == "orthoveda") {
                if(this.hospitalDetails.pharmacyOption==null || this.hospitalDetails.pharmacyOption=='no')
                {
                  this.appPages = [
                    { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                    { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                    { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                    { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                    { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                    { title: 'Bills', url: '/nurse-billing', icon: "card-outline" }                    
                  ];
                }
                else
                {
                  this.appPages = [
                    { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                    { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                    { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                    { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                    { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                    { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                    { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                    { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },
                    { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                    { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                    { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                  ];
                }

                
              }
              else {
                if(this.hospitalDetails.pharmacyOption==null || this.hospitalDetails.pharmacyOption=='no')
                {
                  this.appPages = [
                    { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                    { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                    { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                    { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                    { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },                    
                  ];
                }
                else
                {
                  this.appPages = [
                    { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                    { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                    { title: 'Appointments', url: '/nurse-appointment-list', icon: "calendar-outline" },
                    { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                    { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                    { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                    { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },
                    { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                    { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                    { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                  ];
                }
                
              }
            }
            else {
              if (this.tenantid == "PEAK" || this.tenantid == "orthoveda") {
                this.appPages = [
                  { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                  { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                  { title: 'E-Prescription', url: 'doctor-plus-eprescription/312', icon: "create-outline" },
                  { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                  { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                  { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                  { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                  { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },
                  { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                  { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                  { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                ];
              }
              else {
                this.appPages = [
                  { title: 'Profile', url: '/nurse-profile', icon: "person-outline" },
                  { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                  { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                  { title: 'Vitals', url: '/nurse-vital/219', icon: "newspaper-outline" },
                  { title: 'Bills', url: '/nurse-billing', icon: "card-outline" },
                  { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
                  { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" }, 
                  { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                  { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
                  { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
                ];
              }
            }
          }
          else if(this.userType == "Receptionist") 
          {
            if (SWASTH_CONST.APPOINTMENTMODE == 'Normal') {
              this.appPages = [
                { title: 'Profile', url: '/recep-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Appointments', url: '/recep-appointment-list', icon: "calendar-outline" },
                { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
              ];
            }
            else {
              this.appPages = [
                { title: 'Profile', url: '/recep-profile', icon: "person-outline" },
                { title: 'Patient List', url: '/patient-list', icon: "people-outline" },
                { title: 'Appointments', url: '/calendar', icon: "calendar-outline" },
                { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
              ];
            }
          }
          else if (this.userType == "PharmacyUser") {
            this.appPages = [
              { title: 'Profile', url: '/pharmacy-profile', icon: "person-outline" },
              { title: 'Products', url: '/pharmacy-products', icon: "people-outline" },
              // { title: 'Product Report', url: '/pharmacy-product-report', icon: "people-outline" },
              // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
              // { title: 'Batch', url: '/pharmacy-batch-master', icon: "people-outline" },
              { title: 'Purchase', url: '/pharmacy-purchase', icon: "calendar-outline" },              
              // { title: 'Manufacturers', url: '/pharmacy-manufacturers', icon: "newspaper-outline" },
              { title: 'Bill Report', url: '/billreport', icon: "newspaper-outline" },
                { title: 'Medicine Report', url: '/medicine-report', icon: "newspaper-outline" },
              { title: 'Masters', url: '/pharmacy-master', icon: "newspaper-outline" },
              
              // { title: 'Medicine History', url: '/medicine-history', icon: "newspaper-outline" },
              // { title: 'Bills', url: '/recep-billing', icon: "card-outline" },
              // { title: 'Inventory', url: '/calendar', icon: "newspaper-outline" },
              // { title: 'Supplier', url: '/pharmacy-supplier', icon: "newspaper-outline" },
              // { title: 'Tax', url: '/calendar', icon: "newspaper-outline" }
    
            ];
          }
        });
        }
      }
    }

  }

  async hospListPopover() {
    const popover = await this.popoverCtrl.create({
      component: DoctorHospitalListPage,
      backdropDismiss: false,
      animated: true,
      cssClass: 'hosplist',
    });
    popover.onDidDismiss().then(async data => {
      console.log("data", data);
    });
    popover.present();
  }
}

