import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { tap, map, switchMap, take, catchError } from 'rxjs/operators';
import { SWASTHCONFIG } from '../../config';
import { SwstoragService } from "../storage/swstorag.service";
import { Storage } from '@ionic/storage';
import { ModulesApisService } from '../services/modules-apis.service';
import { WebSocketService } from "./web-socket.service";
import { SWASTH_CONST } from '../constant';

const helper = new JwtHelperService();
const TOKEN_KEY = 'swplus-token';
const REMEMBERME = 'rememberme';
const USERPROFILE = 'USERPROFILE';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isRemember: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  remeStatus = "";

  constructor(private readonly httpClient: HttpClient, private swstorage: SwstoragService,
    private alertCtrl: AlertController, private router: Router, private storage: Storage, private modulesApis: ModulesApisService,
    private webSocket: WebSocketService) {
    this.loadToken();
  }

  async loadToken() {
    if (this.storage.get(TOKEN_KEY)) {
      console.log(': ', this.storage.get(TOKEN_KEY));
      const token = await this.storage.get(TOKEN_KEY);
      console.log('reme : ', this.storage.get(REMEMBERME));
      const rememberSt = await this.storage.get(REMEMBERME);
      if (token) {
        console.log('set token: ', token);
        this.token = token;
        let decodedToken = helper.decodeToken(this.token);
        console.log('decode token: ', decodedToken);
        this.modulesApis.decodedToken = helper.decodeToken(this.token);
        //alert(decodedToken.UID);

        //this.webSocket.subcribe(decodedToken.UID, true, decodedToken.type, decodedToken.TenantID);
        
        // this.webSocket.subcribe('nurse2',false, decodedToken.type, decodedToken.TenantID);
        this.swstorage.getStorage("USERPROFILE").then((udata: any) => {
          console.log("logi user profile-->", udata);
          if(udata.userType=="Doctor")
          {
            this.modulesApis.getDoctorProfile(udata.docprofile.docUserID).subscribe((docProfile:any)=>{
              SWASTH_CONST.APPOINTMENTMODE=docProfile.appointmentViewMode;
              this.isAuthenticated.next(true);
            });
          }
          else if(udata.userType=="Nurse")
          {
          this.modulesApis.getNurseProfile(udata.nurseprofile.nurseUserID).subscribe((nurseProfile:any)=>{
            SWASTH_CONST.APPOINTMENTMODE=nurseProfile.appointmentViewMode;
            this.isAuthenticated.next(true);
          });          

          }
          else if(udata.userType=="PharmacyUser")
          {
            this.modulesApis.getPharmacyProfile(udata.pharmacyprofile.pharUserID).subscribe((nurseProfile:any)=>{
              // SWASTH_CONST.APPOINTMENTMODE=nurseProfile.appointmentViewMode;
              this.isAuthenticated.next(true);
            });
          }
          else{
            this.modulesApis.getRecepProfile(udata.recpprofile.recpUserID).subscribe((recepProfile:any)=>{
              SWASTH_CONST.APPOINTMENTMODE=recepProfile.appointmentViewMode;
              this.isAuthenticated.next(true);
            });
          }
        
          
        });        
      } else {
        this.isAuthenticated.next(false);
      }
      if (rememberSt) {
        console.log('remell set token: ', rememberSt);
        this.remeStatus = rememberSt;
        this.swstorage.getStorage("USERPROFILE").then((udata: any) => {
          console.log("logi user profile-->", udata);
          if(udata.userType=="Doctor")
          {
            this.modulesApis.getDoctorProfile(udata.docprofile.docUserID).subscribe((docProfile:any)=>{
              SWASTH_CONST.APPOINTMENTMODE=docProfile.appointmentViewMode;
              this.isRemember.next(true);
            });
          }
          else if(udata.userType=="Nurse")
          {

            this.modulesApis.getNurseProfile(udata.nurseprofile.nurseUserID).subscribe((nurseProfile:any)=>{
              SWASTH_CONST.APPOINTMENTMODE=nurseProfile.appointmentViewMode;
              this.isRemember.next(true);
            });

          }
          else if(udata.userType=="PharmacyUser")
          {
            this.modulesApis.getPharmacyProfile(udata.pharmacyprofile.pharUserID).subscribe((nurseProfile:any)=>{
              // SWASTH_CONST.APPOINTMENTMODE=nurseProfile.appointmentViewMode;
              this.isRemember.next(true);
            });
          }
            else{

            this.modulesApis.getRecepProfile(udata.recpprofile.recpUserID).subscribe((recepProfile:any)=>{
              SWASTH_CONST.APPOINTMENTMODE=recepProfile.appointmentViewMode;
              this.isRemember.next(true);
            });

          }
        });
        // this.isRemember.next(true);
      } else {
        this.isRemember.next(false);
      }
    }
    else {
      this.isRemember.next(false);
    }
  }

  loginDoctor(values, rememberMe): Observable<any> {
    return this.httpClient.post(`${SWASTHCONFIG.SERVER_URL}/followlogin`, values, { responseType: 'text' }).pipe(
      take(1),
      switchMap(token => {
        if (token !== 'NOTEXISTS') {
          this.token = token;
          // console.log('switch token: ', token);  
          if (rememberMe == 1) {
            from(this.storage.set(REMEMBERME, 'yes'));
          }
          let decodedToken = helper.decodeToken(this.token);
          //alert(decodedToken.UID);
          // this.webSocket.subcribe(decodedToken.UID, true, decodedToken.type, decodedToken.TenantID);
          this.modulesApis.decodedToken = helper.decodeToken(this.token);
          return from(this.storage.set(TOKEN_KEY, token));
        } else if (token == 'NOTEXISTS') {
          this.showToast();
        } else {
          alert("user not exists");
        }
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
        this.isRemember.next(true);
      })
    )
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  loginNurseRecept(values, rememberMe): Observable<any> {
    return this.httpClient.post(`${SWASTHCONFIG.SERVER_URL}/login`, values, { responseType: 'text' }).pipe(
      take(1),
      switchMap(token => {
        if (token !== 'NOTEXISTS') {
          this.token = token;
          // console.log('switch token: ', token);  
          if (rememberMe == 1) {
            from(this.storage.set(REMEMBERME, 'yes'));
          }
          let decodedToken = helper.decodeToken(this.token);
          console.log("nurrecep login-->", decodedToken);
          // this.webSocket.subcribe(decodedToken.UID, false, decodedToken.type, decodedToken.TenantID);
          // this.webSocket.subcribe('nurse2',false, decodedToken.type, decodedToken.TenantID);
          console.log('type: ', decodedToken.type);
          this.modulesApis.decodedToken = helper.decodeToken(this.token);
          return from(this.storage.set(TOKEN_KEY, token));
        } else if (token == 'NOTEXISTS') {
          this.showToast();
        } else {
          alert("user not exists");
        }
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
        this.isRemember.next(true);
      })
    )
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getServerTime() {
    let url = SWASTHCONFIG.LOOKUP_URL + "/getServerDateTime/";
    return this.httpClient.get(url, { responseType: "text" })
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getAppointmentList(apptObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/getdocappointmentlist";
    return this.httpClient.put(epurl, apptObj, { responseType: "json" })
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getAppointmentListnew(apptObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/getdocappointmentlistnew";
    return this.httpClient.put(epurl, apptObj, { responseType: "json" })
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  getAppointmentListnewbranch(apptObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/getdocappointmentlistnewbranch";
    return this.httpClient.put(epurl, apptObj, { responseType: "json" })
    .pipe(
      catchError((err) => {
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )
  }

  createSwasthVideoChatRoom(videoObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/createswasthvideochatroom";
    return this.httpClient.post(epurl, videoObj, { responseType: "text" });
  }

  updateSwasthVideoAppt(apptObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/updateswasthvideoappt";
    return this.httpClient.post(epurl, apptObj, { responseType: "text" });
  }

  // update video consultation of patient
  updateCircleFollowUpAppt(consultObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/updatecirclefollowupappt";
    return this.httpClient.put(epurl, consultObj, { responseType: "text" });
  }

  completeSwasthVideoAppt(apptObj) {
    var epurl = SWASTHCONFIG.SWASTHPLUS + "/completeswasthvideoappt";
    return this.httpClient.post(epurl, apptObj, { responseType: "text" });
  }

  private async showToast() {
    // console.log("Response Message======"+data);      
    const alert = await this.alertCtrl.create({
      header: 'Login Failed',
      message: 'Invalid User ID',
      buttons: ['ok']
    });
    await alert.present();
  }

  logout() {
    this.isAuthenticated.next(false);
    this.isRemember.next(false);
    this.swstorage.removeStorage(TOKEN_KEY);
    this.swstorage.removeStorage(REMEMBERME);
    this.swstorage.removeStorage(USERPROFILE);
    this.swstorage.removeStorage("DOCTORPROFILE");
    this.swstorage.removeStorage("USERPROFILE");
    this.swstorage.clearStorage();
    this.storage.clear();
    // this.webSocket.destroy.next(true);
    SWASTH_CONST.USER_PROFILE = "";
    this.router.navigateByUrl('/login');
  }

}

